<div class="modal fade" role="dialog" aria-modal="true" aria-labelledby="bulkTitle">
  <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="bulkTitle">
          {{ "bulkConfirmStatus" | i18n }}
        </h2>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          appA11yTitle="{{ 'close' | i18n }}"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="card-body text-center" *ngIf="loading">
          <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
          {{ "loading" | i18n }}
        </div>
        <table class="table table-hover table-list" *ngIf="!loading">
          <thead>
            <tr>
              <th colspan="2">{{ "user" | i18n }}</th>
              <th>{{ "status" | i18n }}</th>
            </tr>
          </thead>
          <tr *ngFor="let item of users">
            <td width="30">
              <app-avatar
                [data]="item.user | userName"
                [email]="item.user.email"
                size="25"
                [circle]="true"
                [fontSize]="14"
              ></app-avatar>
            </td>
            <td>
              {{ item.user.email }}
              <small class="text-muted d-block" *ngIf="item.user.name">{{ item.user.name }}</small>
            </td>
            <td class="text-danger" *ngIf="item.error">
              {{ item.message }}
            </td>
            <td *ngIf="!item.error">
              {{ item.message }}
            </td>
          </tr>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">
          {{ "close" | i18n }}
        </button>
      </div>
    </div>
  </div>
</div>
