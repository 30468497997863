<div class="modal fade" role="dialog" aria-modal="true" aria-labelledby="restoreSelectedTitle">
  <div class="modal-dialog modal-dialog-scrollable modal-sm" role="document">
    <form class="modal-content" #form (ngSubmit)="submit()" [appApiAction]="formPromise">
      <div class="modal-header">
        <h2 class="modal-title" id="restoreSelectedTitle">
          {{ "restoreSelected" | i18n }}
        </h2>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          appA11yTitle="{{ 'close' | i18n }}"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{ "restoreSelectedItemsDesc" | i18n: cipherIds.length }}
      </div>
      <div class="modal-footer">
        <button
          appAutoFocus
          type="submit"
          class="btn btn-primary btn-submit"
          [disabled]="form.loading"
        >
          <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
          <span>{{ "restore" | i18n }}</span>
        </button>
        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">
          {{ "cancel" | i18n }}
        </button>
      </div>
    </form>
  </div>
</div>
