<app-navbar></app-navbar>
<div class="org-nav" *ngIf="organization">
  <div class="container d-flex">
    <div class="d-flex flex-column">
      <div class="my-auto d-flex align-items-center pl-1">
        <app-avatar [data]="organization.name" size="45" [circle]="true"></app-avatar>
        <div class="org-name ml-3">
          <span>{{ organization.name }}</span>
          <small class="text-muted">{{ "organization" | i18n }}</small>
        </div>
        <div
          class="ml-3 card border-danger text-danger bg-transparent"
          *ngIf="!organization.enabled"
        >
          <div class="card-body py-2">
            <i class="bwi bwi-exclamation-triangle" aria-hidden="true"></i>
            {{ "organizationIsDisabled" | i18n }}
          </div>
        </div>
        <div
          class="ml-3 card border-info text-info bg-transparent"
          *ngIf="organization.isProviderUser"
        >
          <div class="card-body py-2">
            <i class="bwi bwi-exclamation-triangle" aria-hidden="true"></i>
            {{ "accessingUsingProvider" | i18n: organization.providerName }}
          </div>
        </div>
      </div>
      <ul class="nav nav-tabs" *ngIf="showMenuBar">
        <li class="nav-item">
          <a class="nav-link" routerLink="vault" routerLinkActive="active">
            <i class="bwi bwi-lock" aria-hidden="true"></i>
            {{ "vault" | i18n }}
          </a>
        </li>
        <li class="nav-item" *ngIf="showManageTab">
          <a class="nav-link" [routerLink]="manageRoute" routerLinkActive="active">
            <i class="bwi bwi-sliders" aria-hidden="true"></i>
            {{ "manage" | i18n }}
          </a>
        </li>
        <li class="nav-item" *ngIf="showToolsTab">
          <a class="nav-link" [routerLink]="toolsRoute" routerLinkActive="active">
            <i class="bwi bwi-wrench" aria-hidden="true"></i>
            {{ "tools" | i18n }}
          </a>
        </li>
        <li class="nav-item" *ngIf="organization.isOwner">
          <a class="nav-link" routerLink="settings" routerLinkActive="active">
            <i class="bwi bwi-cogs" aria-hidden="true"></i>
            {{ "settings" | i18n }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
<app-footer></app-footer>
