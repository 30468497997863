<app-callout type="info" *ngIf="vaultTimeoutPolicy">
  {{ "vaultTimeoutPolicyInEffect" | i18n: vaultTimeoutPolicyHours:vaultTimeoutPolicyMinutes }}
</app-callout>

<div [formGroup]="form">
  <div class="form-group">
    <label for="vaultTimeout">{{ "vaultTimeout" | i18n }}</label>
    <select
      id="vaultTimeout"
      name="VaultTimeout"
      formControlName="vaultTimeout"
      class="form-control"
    >
      <option *ngFor="let o of vaultTimeouts" [ngValue]="o.value">{{ o.name }}</option>
    </select>
    <small class="form-text text-muted">{{ "vaultTimeoutDesc" | i18n }}</small>
  </div>
  <div class="form-group" *ngIf="showCustom" formGroupName="custom">
    <label for="customVaultTimeout">{{ "customVaultTimeout" | i18n }}</label>
    <div class="row">
      <div class="col-6">
        <input
          id="hours"
          class="form-control"
          type="number"
          min="0"
          name="hours"
          formControlName="hours"
        />
        <small>{{ "hours" | i18n }}</small>
      </div>
      <div class="col-6">
        <input
          id="minutes"
          class="form-control"
          type="number"
          min="0"
          name="minutes"
          formControlName="minutes"
        />
        <small>{{ "minutes" | i18n }}</small>
      </div>
    </div>
  </div>
</div>
