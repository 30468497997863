<app-callout type="info" *ngIf="showKeyConnectorInfo">
  {{ "keyConnectorPolicyRestriction" | i18n }}
</app-callout>

<app-callout type="warning">
  {{ "resetPasswordPolicyWarning" | i18n }}
</app-callout>

<div class="form-group">
  <div class="form-check">
    <input
      class="form-check-input"
      type="checkbox"
      id="enabled"
      [formControl]="enabled"
      name="Enabled"
    />
    <label class="form-check-label" for="enabled">{{ "enabled" | i18n }}</label>
  </div>
</div>

<div [formGroup]="data">
  <h3 class="mt-4">{{ "resetPasswordPolicyAutoEnroll" | i18n }}</h3>
  <p>{{ "resetPasswordPolicyAutoEnrollDescription" | i18n }}</p>
  <app-callout type="warning">
    {{ "resetPasswordPolicyAutoEnrollWarning" | i18n }}
  </app-callout>
  <div class="form-check">
    <input
      class="form-check-input"
      type="checkbox"
      id="autoEnrollEnabled"
      name="AutoEnrollEnabled"
      formControlName="autoEnrollEnabled"
    />
    <label class="form-check-label" for="autoEnrollEnabled">
      {{ "resetPasswordPolicyAutoEnrollCheckbox" | i18n }}
    </label>
  </div>
</div>
