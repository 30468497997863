<div class="page-header">
  <h1>{{ "options" | i18n }}</h1>
</div>
<p>{{ "optionsDesc" | i18n }}</p>
<form (ngSubmit)="submit()" ngNativeValidate>
  <div class="row">
    <div class="col-6">
      <app-vault-timeout-input
        [vaultTimeouts]="vaultTimeouts"
        [formControl]="vaultTimeout"
        ngDefaultControl
      >
      </app-vault-timeout-input>
    </div>
  </div>
  <div class="form-group">
    <label>{{ "vaultTimeoutAction" | i18n }}</label>
    <div class="form-check form-check-block">
      <input
        class="form-check-input"
        type="radio"
        name="vaultTimeoutAction"
        id="vaultTimeoutActionLock"
        value="lock"
        [(ngModel)]="vaultTimeoutAction"
      />
      <label class="form-check-label" for="vaultTimeoutActionLock">
        {{ "lock" | i18n }}
        <small>{{ "vaultTimeoutActionLockDesc" | i18n }}</small>
      </label>
    </div>
    <div class="form-check mt-2 form-check-block">
      <input
        class="form-check-input"
        type="radio"
        name="vaultTimeoutAction"
        id="vaultTimeoutActionLogOut"
        value="logOut"
        [(ngModel)]="vaultTimeoutAction"
        (ngModelChange)="vaultTimeoutActionChanged($event)"
      />
      <label class="form-check-label" for="vaultTimeoutActionLogOut">
        {{ "logOut" | i18n }}
        <small>{{ "vaultTimeoutActionLogOutDesc" | i18n }}</small>
      </label>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <div class="d-flex">
          <label for="locale">{{ "language" | i18n }}</label>
          <a
            class="ml-auto"
            href="https://bitwarden.com/help/localization/"
            target="_blank"
            rel="noopener"
            appA11yTitle="{{ 'learnMore' | i18n }}"
          >
            <i class="bwi bwi-question-circle" aria-hidden="true"></i>
          </a>
        </div>
        <select id="locale" name="Locale" [(ngModel)]="locale" class="form-control">
          <option *ngFor="let o of localeOptions" [ngValue]="o.value">{{ o.name }}</option>
        </select>
        <small class="form-text text-muted">{{ "languageDesc" | i18n }}</small>
      </div>
    </div>
  </div>
  <div class="form-group">
    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        id="disableIcons"
        name="DisableIcons"
        [(ngModel)]="disableIcons"
      />
      <label class="form-check-label" for="disableIcons">
        {{ "disableIcons" | i18n }}
      </label>
      <a
        href="https://bitwarden.com/help/website-icons/"
        target="_blank"
        rel="noopener"
        appA11yTitle="{{ 'learnMore' | i18n }}"
      >
        <i class="bwi bwi-question-circle" aria-hidden="true"></i>
      </a>
    </div>
    <small class="form-text text-muted">{{ "disableIconsDesc" | i18n }}</small>
  </div>
  <div class="form-group">
    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        id="enableGravatars"
        name="enableGravatars"
        [(ngModel)]="enableGravatars"
      />
      <label class="form-check-label" for="enableGravatars">
        {{ "enableGravatars" | i18n }}
      </label>
      <a
        href="https://gravatar.com/"
        target="_blank"
        rel="noopener"
        appA11yTitle="{{ 'learnMore' | i18n }}"
      >
        <i class="bwi bwi-question-circle" aria-hidden="true"></i>
      </a>
    </div>
    <small class="form-text text-muted">{{ "enableGravatarsDesc" | i18n }}</small>
  </div>
  <div class="form-group">
    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        id="enableFullWidth"
        name="enableFullWidth"
        [(ngModel)]="enableFullWidth"
      />
      <label class="form-check-label" for="enableFullWidth">
        {{ "enableFullWidth" | i18n }}
      </label>
    </div>
    <small class="form-text text-muted">{{ "enableFullWidthDesc" | i18n }}</small>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label for="theme">{{ "theme" | i18n }}</label>
        <select id="theme" name="theme" [(ngModel)]="theme" class="form-control">
          <option *ngFor="let o of themeOptions" [ngValue]="o.value">{{ o.name }}</option>
        </select>
        <small class="form-text text-muted">{{ "themeDesc" | i18n }}</small>
      </div>
    </div>
  </div>
  <button type="submit" class="btn btn-primary">
    {{ "save" | i18n }}
  </button>
</form>
