<div class="page-header">
  <h1>{{ "sponsoredFamilies" | i18n }}</h1>
</div>
<ng-container *ngIf="loading">
  <i class="bwi bwi-spinner bwi-spin text-muted" title="{{ 'loading' | i18n }}"></i>
  <span class="sr-only">{{ "loading" | i18n }}</span>
</ng-container>
<ng-container *ngIf="!loading">
  <p>
    {{ "sponsoredFamiliesEligible" | i18n }}
  </p>
  <div>
    {{ "sponsoredFamiliesInclude" | i18n }}:
    <ul class="inset-list">
      <li>{{ "sponsoredFamiliesPremiumAccess" | i18n }}</li>
      <li>{{ "sponsoredFamiliesSharedCollections" | i18n }}</li>
    </ul>
  </div>
  <form
    #form
    (ngSubmit)="submit()"
    [appApiAction]="formPromise"
    ngNativeValidate
    *ngIf="anyOrgsAvailable"
  >
    <div *ngIf="moreThanOneOrgAvailable" class="form-group col-7">
      <label for="availableSponsorshipOrg">{{ "familiesSponsoringOrgSelect" | i18n }}</label>
      <select
        id="availableSponsorshipOrg"
        name="Available Sponsorship Organization"
        [(ngModel)]="selectedSponsorshipOrgId"
        class="form-control"
        required
      >
        <option value="">-- {{ "select" | i18n }} --</option>
        <option *ngFor="let o of availableSponsorshipOrgs" [ngValue]="o.id">{{ o.name }}</option>
      </select>
    </div>
    <div class="form-group col-7">
      <label for="accountEmail">{{ "sponsoredFamiliesEmail" | i18n }}:</label>
      <input
        id="accountEmail"
        class="form-control"
        inputmode="email"
        [(ngModel)]="sponsorshipEmail"
        name="sponsorshipEmail"
        required
      />
      <button class="btn btn-primary btn-submit mt-4" type="submit" [disabled]="form.loading">
        <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
        <span>{{ "redeem" | i18n }}</span>
      </button>
    </div>
  </form>
  <ng-container *ngIf="anyActiveSponsorships">
    <div class="border-bottom">
      <table class="table table-hover table-list">
        <thead>
          <tr>
            <th>{{ "recipient" | i18n }}</th>
            <th>{{ "sponsoringOrg" | i18n }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let o of activeSponsorshipOrgs">
            <tr sponsoring-org-row [sponsoringOrg]="o" (sponsorshipRemoved)="load(true)"></tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <small>{{ "sponsoredFamiliesLeaveCopy" | i18n }}</small>
  </ng-container>
</ng-container>
