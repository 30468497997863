<div class="modal fade" role="dialog" aria-modal="true" aria-labelledby="deleteOrganizationTitle">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <form
      class="modal-content"
      #form
      (ngSubmit)="submit()"
      [appApiAction]="formPromise"
      ngNativeValidate
      *ngIf="loaded"
    >
      <div class="modal-header">
        <h2 class="modal-title" id="deleteOrganizationTitle">{{ "deleteOrganization" | i18n }}</h2>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          appA11yTitle="{{ 'close' | i18n }}"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-callout type="warning">{{
          "deletingOrganizationIsPermanentWarning" | i18n: organizationName
        }}</app-callout>
        <p id="organizationDeleteDescription">
          <ng-container
            *ngIf="
              deleteOrganizationRequestType === 'InvalidFamiliesForEnterprise';
              else regularDelete
            "
          >
            {{ "orgCreatedSponsorshipInvalid" | i18n }}
          </ng-container>
          <ng-template #regularDelete>
            <ng-container *ngIf="organizationContentSummary.totalItemCount > 0">
              {{ "deletingOrganizationContentWarning" | i18n: organizationName }}
              <ul>
                <li *ngFor="let type of organizationContentSummary.itemCountByType">
                  {{ type.count }} {{ type.localizationKey | i18n }}
                </li>
              </ul>
              {{ "deletingOrganizationActiveUserAccountsWarning" | i18n }}
            </ng-container>
          </ng-template>
        </p>
        <app-verify-master-password [(ngModel)]="masterPassword" ngDefaultControl name="secret">
        </app-verify-master-password>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-danger btn-submit" [disabled]="form.loading">
          <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
          <span>{{ "deleteOrganization" | i18n }}</span>
        </button>
        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">
          {{ "close" | i18n }}
        </button>
      </div>
    </form>
  </div>
</div>
