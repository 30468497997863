<form #form (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate>
  <div class="modal-body">
    <p>{{ "twoStepLoginAuthDesc" | i18n }}</p>
    <app-verify-master-password [(ngModel)]="secret" ngDefaultControl name="secret">
    </app-verify-master-password>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
      <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
      <span>{{ "continue" | i18n }}</span>
    </button>
    <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">
      {{ "close" | i18n }}
    </button>
  </div>
</form>
